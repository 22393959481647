import { defineStore } from 'pinia';
import type { ProductsList } from '@/types/products';
import type { MobileProductsList } from '~/types/mobile-products';
import productList from '@/lib/products';
import mobileProductsList from '~/lib/mobile-products';

interface State {
  products: ProductsList;
  mobileProducts: MobileProductsList;
}

export const useProducts = defineStore('products', {
  state: (): State => ({
    products: productList(),
    mobileProducts: mobileProductsList(),
  }),
  getters: {
    plans(state) {
      return state.products.plans;
    },
    supportPlans(state) {
      return state.products.supportPlans;
    },
    terms(state) {
      return state.products.terms;
    },
    setup(state) {
      return state.products.setup;
    },
    mobilePlans(state) {
      return state.mobileProducts.plans;
    },
  },
});