import { defineStore } from 'pinia';
import { type LooseObject, type Nullable } from '@/types/generic';
import { handleError } from '~/utils/errors';
import { useProducts } from '~/stores/products';
import { SupportPlan } from '~/types/products';
import { StripeSubscription, StripeSubscriptionItem } from '~/types/subscriptions';
import { useMemberSignup } from '~/stores/signup';
import { v4 as uuidv4 } from 'uuid';


interface PortRequestData {
  accountType: string;
  currentProvider: string;
  nameOnAccount: string | null;
  accountReference: string | null;
  simSerial: string | null;
  serviceNumber: string;
  orderPlan: string;
  entity_id: string;
  FlexAccountCode: string;
  SubscriptionId: string;
}

interface LosingServiceProviderDetail {
  serviceEntity: string;
  serviceProvider: string;
  accountName: string | null;
  accountNumber?: string | null;
  simSerialNumber?: string | null;
}

interface PortValidationRequest {
  accountNumber: string | null;
  simSerialNumber: string | null;
  providerKey: string;
}

interface PortValidationResponse {
  success: boolean;
  message: string;
  details?: {
    code?: string;
    reason?: string;
  };
}

export interface CustomerDetails {
  name: string;
  phone: string;
  plan?: LooseObject;
  referrer?: string;
  promo?: string;
}

export interface Customer {
  id: number;
  auth0_sub: string;
  name: string;
  email: string;
  phone: string;
  stripe_id: string;
  created_at: string;
  updated_at: string;
}

export interface SubscriptionsList {
  broadband: StripeSubscription[];
  mobile: StripeSubscription[];
  support: StripeSubscription[];
  // pending: PendingSubscription[];
}

export interface SimBlockRequest {
  simBlockingEnabled: boolean;
}

interface State {
  user: Nullable<Customer>;
  subscriptions: {
    active: StripeSubscription[];
    // pending: PendingSubscription[];
  };
  subscriptionsLoaded: boolean;
  isLoading: boolean;
  supportPlan: number;
  ServiceProviders: Nullable<LooseObject>;
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: null,
    subscriptions: {
      active: [],
      //pending: [],
    },
    subscriptionsLoaded: false,
    isLoading: false,
    supportPlan: 1,
    ServiceProviders: null,
  }),

  getters: {
    customerExists(state) {
      return !!state.user;
    },

    supportPlanProduct(state) {
      const products = useProducts();
      return products.supportPlans.find((plan: SupportPlan) => plan.id === state.supportPlan);
    },

    hasPremiumSupportPlan(state) {
      return state.supportPlan === 2;
    },

    subscriptionsList(state): SubscriptionsList {
      const list: SubscriptionsList = {
        broadband: [],
        mobile: [],
        support: [],
        //pending: [],
      };

      const products = useProducts();
      const productList = products.products.plans;

      // Get support plan price IDs
      const supportPlansPriceIds = products.supportPlans.reduce((curr: string[], plan): string[] => {
        if (plan.stripePriceCode) {
          curr.push(plan.stripePriceCode);
        }
        if (plan.stripeSignupPriceCode) {
          curr.push(plan.stripeSignupPriceCode);
        }
        return curr;
      }, []);

      // Get mobile product codes
      const mobilePlanProductIds = [
        ...productList
          .filter(plan => plan.planKey?.startsWith('mobile-'))
          .map(plan => plan.stripeCodeNoTermProduct || plan.stripeCode),
        ...products.mobileProducts.plans
          .map(plan => plan.stripeCode)
      ];

      // Get broadband product codes
      const broadbandProductIds = [...new Set(productList
        .filter(plan => plan.planKey?.startsWith('fibre-'))
        .flatMap(plan => [plan.stripeCode, plan.stripeCodeNoTermProduct]))];


      state.subscriptions.active.forEach((subscription: StripeSubscription) => {
        const items = subscription.items?.data || [];

        items.forEach((item: StripeSubscriptionItem) => {
          if (!item.plan || !item.plan.product) return;

          const subscriptionWithItem: StripeSubscription = {
            ...subscription,
            plan: item.plan
          };

          // Check category for this subscription item
          const isSupport = supportPlansPriceIds.includes(item.plan.id);
          const isMobile = mobilePlanProductIds.includes(item.plan.product);
          const isBroadband = broadbandProductIds.includes(item.plan.product);

          if (isSupport) {
            list.support.push(subscriptionWithItem);
          } else if (isMobile) {
            list.mobile.push(subscriptionWithItem);
          } else if (isBroadband) {
            list.broadband.push(subscriptionWithItem);
          }
        });
      });

      //list.pending = state.subscriptions.pending;

      return list;
    },

    monthlyCost(state): number {
      if (!state.subscriptions.active.length) {
        return 0;
      }
      const prices: number[] = [];
      state.subscriptions.active.forEach((subscription: StripeSubscription) => {
        const items = subscription.items?.data || [];
        items.forEach((item: StripeSubscriptionItem) => {
          if (item.plan?.amount) {
            prices.push(item.plan.amount);
          }
        });
      });
      return prices.reduce((a, b) => a + b, 0);
    },
  },

  actions: {
    async loadUser(captureLead: boolean = false) {
      if (this.isLoading || this.user) {
        return;
      }
      this.isLoading = true;
      try {
        const accessToken = await this.$auth0.getAccessTokenSilently();
        if (!accessToken) {
          this.isLoading = false;
          this.user = null;
          return;
        }
        const response = await this.$api.get<Customer>('/customer', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (response.status === 200 || response.status === 201) {
          this.user = response._data.data;
          this.isLoading = false;
        }
      } catch (err: any) {
        if (err.statusCode === 402 && captureLead) {
          await this.captureLead();
        }
        this.user = null;
        this.isLoading = false;
      }
    },

    async createUser(details: CustomerDetails) {
      const accessToken = await this.$auth0.getAccessTokenSilently();

      const submitData: CustomerDetails = {
        name: details.name,
        phone: details.phone,
      };

      if (details.plan) {
        submitData.plan = details.plan;
      }
      if (details.promo) {
        submitData.promo = details.promo;
      }
      if (details.referrer) {
        submitData.referrer = details.referrer;
      }

      try {
        const response = await this.$api.post<Customer, CustomerDetails>('/customer', submitData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200 || response.status === 201) {
          this.user = response._data.data;
        }
      } catch (err) {
        this.user = null;
        handleError(err);
      }
    },

    async updateUser(details: CustomerDetails) {
      const accessToken = await this.$auth0.getAccessTokenSilently();

      if (!this.customerExists) {
        return this.createUser(details);
      }

      const submitData: CustomerDetails = {
        name: details.name,
        phone: details.phone,
      };

      if (details.plan) {
        submitData.plan = details.plan;
      }
      if (details.promo) {
        submitData.promo = details.promo;
      }
      if (details.referrer) {
        submitData.referrer = details.referrer;
      }

      try {
        const response = await this.$api.put<Customer, CustomerDetails>('/customer', submitData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200 || response.status === 201) {
          this.user = response._data.data;
        }
        return true;
      } catch (err) {
        this.user = null;
        handleError(err);
      }
      return false;
    },

    async captureLead(): Promise<boolean> {
      const accessToken = await this.$auth0.getAccessTokenSilently();
      if (this.customerExists) {
        return true;
      }
      try {
        const signup = useMemberSignup();
        const submitData = signup.signupDetails.referrer ? { referrer: signup.signupDetails.referrer } : {};

        const response = await this.$api.post('/customer/capture', submitData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200 || response.status === 201) {
          return true;
        }
      } catch (err) {
        handleError(err);
      }
      return false;
    },

    async fetchSubscriptions() {
      if (this.subscriptionsLoaded) {
        return;
      }
      this.isLoading = true;
      const accessToken = await this.$auth0.getAccessTokenSilently();

      try {
        const response = await this.$topsoilApi.get<{ active: StripeSubscription[] }>('/customer/subscriptions',
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
        if (response.status === 200) {
          this.subscriptions = response._data || [];
          this.subscriptionsLoaded = true;
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        handleError(err);
      }
    },

    async fetchServiceProviders() {
      const accessToken = await this.$auth0.getAccessTokenSilently();
      try {
        const response = await this.$topsoilApi.get<{ serviceProviders: string[] }>('/serviceProviders', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200) {
          this.ServiceProviders = response._data.serviceProviders;
          return this.ServiceProviders;
        }
      } catch (err) {
        handleError(err);
      }
      return [];
    },
    async updateSimBlock(accountId: string, serviceNumber: string, enabled: boolean) {
      const accessToken = await this.$auth0.getAccessTokenSilently();

      try {
        const response = await this.$topsoilApi.post<{ success: boolean; message: string }, SimBlockRequest>(
          `/mobile/services/${accountId}/simBlock/${serviceNumber}`,
          { simBlockingEnabled: enabled },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.status === 200) {
          return {
            success: true,
            message: response._data.message
          };
        }

        return {
          success: false,
          message: 'Failed to update SIM block status'
        };
      } catch (err) {
        handleError(err);
        return {
          success: false,
          message: 'An error occurred while updating SIM block status'
        };
      }
    },
    async validatePort(data: PortValidationRequest): Promise<PortValidationResponse> {
      const accessToken = await this.$auth0.getAccessTokenSilently();

      const validationPayload = {
        providerKey: data.providerKey,
        ...(data.accountNumber ? { accountNumber: data.accountNumber } : {}),
        ...(data.simSerialNumber ? { simSerialNumber: data.simSerialNumber } : {})
      };

      try {
        const response = await this.$topsoilApi.post('/port/validate', validationPayload, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Check if response has data property
        if (response.status === 200) {
          // If the backend returns a specific format, use it
          if (response._data) {
            return {
              success: true,
              message: response._data.message || 'Port validation successful',
              details: response._data.details
            };
          }

          // Default successful response
          return {
            success: true,
            message: 'Port validation successful'
          };
        }

        // Handle unsuccessful response
        return {
          success: false,
          message: response._data?.message || 'Failed to validate port details'
        };
      } catch (err: any) {
        // Handle error response from backend
        if (err.response?._data) {
          return {
            success: false,
            message: err.response._data.detail || 'Failed to validate port',
            details: {
              code: err.response._data.code,
              reason: err.response._data.detail
            }
          };
        }

        handleError(err);
        return {
          success: false,
          message: 'An error occurred while validating port'
        };
      }
    },
    // Add this method to the actions in auth.ts
    async createPortRequest(data: PortRequestData) {
      const accessToken = await this.$auth0.getAccessTokenSilently();


      // Create losing service provider details based on account type
      const losingServiceProviderDetail: LosingServiceProviderDetail = {
        serviceEntity: data.serviceNumber,
        serviceProvider: data.currentProvider,
        accountName: data.nameOnAccount,
      };

      // Add either account number or sim serial number based on account type
      if (data.accountType === 'invoiced') {
        losingServiceProviderDetail.accountNumber = data.accountReference;
      } else {
        losingServiceProviderDetail.simSerialNumber = data.simSerial;
      }

      const portData = {
        salesChannel: 'SelfService',
        salesPerson: 'SelfService',
        FlexAccountCode: data.FlexAccountCode,
        SubscriptionId: data.SubscriptionId,
        orderToken: uuidv4(),
        losingServiceProviderDetails: [losingServiceProviderDetail],
        additionalProducts: [
          {
            serviceEntity: data.serviceNumber,
            service: 'mobile2_linerental',
            ratePlan: data.orderPlan
          }
        ],
        additionalProductsToChange: [
          {
            serviceEntity: data.entity_id,
            service: 'mobile2_linerental'
          }
        ]
      };


      try {
        const response = await this.$topsoilApi.post('/port/create', portData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (response.status === 200) {
          return {
            success: true,
            message: 'Port request created successfully'
          };
        }

        return {
          success: false,
          message: 'Failed to create port request'
        };
      } catch (err) {
        handleError(err);
        return {
          success: false,
          message: 'An error occurred while creating port request'
        };
      }
    }
  },
});