import type { MobileProductsList } from '@/types/mobile-products';
import { useRuntimeConfig } from 'nuxt/app';

const mobileProductsList = (): MobileProductsList => {
  const inProduction = useRuntimeConfig().public.stripeProducts === 'live';

  return {
    promo: [
      {
        name: 'signup',
        title: '15% off discount',
        description: 'Enjoy 15% off for the first 4 months',
        benefit: '4 months 15% off',
        term: 4,
      },
      {
        name: 'fnf',
        title: 'Friends & Family 3 month Discount',
        description: 'Zeronet friends and family enjoy %15 off all our services for 3 months',
        benefit: 'F&F 3 months 15% off',
        term: 4,
      },
    ],
    plans: [
      {
        id: 1,
        planKey: 'mobile-starter',
        name: 'Mobile Starter',
        stripeCode: inProduction ? 'prod_R8PHqupQSihRRE' : 'prod_R8PHqupQSihRRE',
        stripePriceCode: inProduction ? 'price_1QG8SSLyen3md8eTCUVNAV3w' : 'price_1QAGvtLyen3md8eTTvf31TmM',
        price: 2499,
        features: ['Up to 3Mbps Data', 'SD (480p) Video'],
        summaryFeatures: [
          'Truly Unlimited Data up to 10Mbps speeds',
          'Includes Unimited NZ & AU calling, SMS and MMS',
          'Wi-Fi Calling and Unlimited Hotspotting',
        ],
        promo: {
          signup: {
            price: 2125,
            discount: -374,
            stripePriceCode: '',
          },
          fnf: {
            price: 2125,
            discount: -374,
            stripePriceCode: '',
          },
        },
      },
      {
        id: 2,
        name: 'Mobile Essentials',
        planKey: 'mobile-essentials',
        stripeCode: inProduction ? 'prod_R8PbOGdNXfplRC' : 'prod_R8PbOGdNXfplRC',
        stripePriceCode: inProduction ? 'price_1QG8mgLyen3md8eTAZMYu2ld' : 'price_1QAH2xLyen3md8eT0sw05Eb3',
        price: 4999,
        isMostPopular: true,
        features: ['Up to 10Mbps Data', 'HD (720p) Video'],
        promo: {
          signup: {
            price: 4250,
            discount: -749,
            term: 4,
            stripePriceCode: '',
          },
          fnf: {
            price: 4250,
            discount: -749,
            term: 4,
            stripePriceCode: '',
          },
        },
      },
      {
        id: 3,
        name: 'Mobile Max',
        planKey: 'mobile-max',
        stripeCode: inProduction ? 'prod_R8PdYLWB0dlJ3I' : 'prod_R8PdYLWB0dlJ3I',
        stripePriceCode: inProduction ? 'price_1QG8ntLyen3md8eTGsvBNKj8' : 'price_1Pn84vLyen3md8eTkSNo4FIF',
        price: 7499,
        features: ['Max Data Speeds', '4K Video'],
        promo: {
          signup: {
            price: 6375,
            discount: -1124,
            term: 4,
            stripePriceCode: '',
          },
          fnf: {
            price: 6375,
            discount: -1124,
            term: 4,
            stripePriceCode: '',
          },
        },
      },
    ],
    setup: {
      id: 1,
      name: 'Setup Fee',
      price: 499,
      stripeCode: inProduction ? '' : '',
      stripePriceCode: inProduction ? 'price_1QG8dtLyen3md8eTbDGnbPKM' : 'price_1QG8gLLyen3md8eTBzsyLCTP',
      immediateCharge: true,
      promo: {
        fnf: {
          price: 50,
          stripePriceCode: inProduction ? 'price_1QG8dtLyen3md8eTrOnNG0PE' : 'price_1QG8gLLyen3md8eTbgLxbl8K',
        },
      },
    },
    delivery: {
      id: 1,
      name: 'Delivery Fee',
      price: 1499,
      stripeCode: inProduction ? '' : '',
      stripePriceCode: inProduction ? 'price_1NzRviLyen3md8eTREveowrd' : 'price_1NzRksLyen3md8eTRXI2BXne',
      immediateCharge: true,
      promo: {
        fnf: {
          price: 50,
          stripePriceCode: inProduction ? 'price_1QG8YaLyen3md8eTG4tnFGFc' : 'price_1QG8akLyen3md8eTfnbpDmVK',
        },
      },
    },
    discounts: {
      referralDiscount: {
        name: 'Referral Discount',
        title: 'Refer-a-friend Discount 50% off',
        discount: -0.5, // 50% off
      },
      // signupDiscount: {
      //   name: 'Signup Discount',
      //   title: '',
      //   discount: 0,
      // },
    },
  };
};

export default mobileProductsList;
